@import "../index"

.divider
    background: #f5f5f5
    width: 100%
    height: 2px
    border-radius: 1px

.product
    aspect-ratio: 1.6
    position: relative
    display: flex
    align-items: center
    justify-content: center
    text-decoration: none !important
    margin: $padding-s
    &:hover
        text-decoration: none !important
        border-bottom: none !important
    .image, .overlay
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        z-index: -1
        object-fit: cover
    .title
        @extend .title
        color: black
        opacity: 0
        transition: 0.2s ease
        text-align: center
    .overlay
        background: white
        opacity: 0
        transition: 0.2s ease
    &:hover
        .title
            opacity: 1
        .overlay
            opacity: 0.4

.ig
    border: none
    width: 100%
    aspect-ratio: 0.52

@media (max-width: 767.98px)
    .product
        height: 300px
        width: 100%
        aspect-ratio: auto
    .ig
        height: 640px
        width: 100%
