/**
 *  Padding
 */

$padding-xxs: 2px
$padding-xs: 4px
$padding-s: 8px
$padding-m: 16px
$padding-l: 32px
$padding-xl: 64px
$padding-xxl: 128px

@font-face
    font-family: 'Sofia Pro'
    font-weight: 700
    src: url("./assets/fonts/sofiapro-regular.otf")

@font-face
    font-family: 'Sofia Pro'
    font-weight: bolder
    src: url("./assets/fonts/sofiapro-bold.otf")

@font-face
    font-family: 'Sofia Pro'
    font-weight: 300
    src: url("./assets/fonts/sofiapro-extra-light.otf")

@font-face
    font-family: 'Sofia Pro'
    font-weight: 500
    src: url("./assets/fonts/sofiapro-light.otf")

@font-face
    font-family: 'Sofia Pro'
    font-weight: bold
    src: url("./assets/fonts/sofiapro-semi-bold.otf")

@font-face
    font-family: 'Yuji'
    font-weight: 700
    src: url("./assets/fonts/yuji.ttf")

@font-face
    font-family: 'Brandon'
    font-weight: normal
    src: url("./assets/fonts/brandon-regular.otf")

@font-face
    font-family: 'Brandon'
    font-weight: bold
    src: url("./assets/fonts/brandon-bold.otf")

@font-face
    font-family: 'Brandon'
    font-weight: 500
    src: url("./assets/fonts/brandon-light.otf")

@font-face
    font-family: 'Brandon'
    font-weight: 300
    src: url("./assets/fonts/brandon-extra-light.otf")

body
    font-family: 'Sofia Pro'
    font-weight: 500

a
    color: black !important
    text-decoration: none !important
    &:hover
        border-bottom: 1px solid black

h1, h2, h3, .title
    font-family: 'Brandon'
    font-weight: 400

h4, h5, h6, .subtitle
    font-family: 'Sofia Pro'
    font-weight: bold

p, .mono
    font-family: 'Yuji' !important
    font-weight: 700
