@import "../index"

.main
    margin-top: $padding-l
    .logo
        height: 24px
    .splash
        min-height: 60vh
        padding: $padding-l
        display: flex
        text-align: center
        flex-direction: column
        align-items: center
        justify-content: center
        padding-top: $padding-xxl
        padding-bottom: $padding-xxl
        position: relative
        .circles
            position: absolute
            width: 100%
            max-width: 500px
            opacity: 0.2
            position: absolute
            margin-top: $padding-l
            margin-bottom: $padding-l
        .splashInner
            position: absolute
            z-index: 1
            left: 0
            right: 0
            padding-left: $padding-m
            padding-right: $padding-m
            .shopNow
                position: relative
                display: inline-block
                border: 0
                background: #424242
                color: white !important
                padding: $padding-s
                padding-left: $padding-l
                padding-right: $padding-l
                border-radius: $padding-xs
                margin-top: $padding-m
                .shopNowInner
                    display: flex
                    align-items: center
                    &:hover
                        opacity: 0.8
                    .shopNowText
                        margin-left: $padding-s
    .products
        padding: 0
        padding-top: $padding-l
        padding-bottom: $padding-l
    .footer
        padding-top: $padding-xl
        padding-bottom: $padding-xl
        .footerLeft
            padding: 0
            padding-right: $padding-l
        .footerRight
            display: flex
            flex-direction: column
            align-items: flex-end
            justify-content: flex-end
            padding: 0
        .smallFont
            font-size: 9pt
    .navLogo, .navLink
        border-bottom: none !important
    .navLink
        opacity: 0.5
        &:hover
            opacity: 1
